import api from './base';
const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    payment: builder.mutation({
      query: (body) => ({
        url: 'payment/student/',
        method: 'POST',
        body,
      }),
    }),

    requestWithdrawalMoney: builder.mutation({
      query: (payload) => ({
        url: 'withdrawal-request/',
        method: 'POST',
        body: { currency: 'UAH', ...payload },
      }),
    }),

    // TODO: Remove the LiqPay logic
    createLiqPayForm: builder.mutation({
      query: (lessonId) => ({
        url: 'financial/liqpay-payment',
        method: 'POST',
        body: { lessonId },
      }),
    }),

    createLessonPayment: builder.mutation({
      query: (lessonId) => ({
        url: 'financial/lesson-payment',
        method: 'POST',
        body: { lessonId },
      }),
    }),
  }),
});

export const {
  usePaymentMutation,
  useRequestWithdrawalMoneyMutation,
  // TODO: Remove the LiqPay logic
  useCreateLiqPayFormMutation,
  useCreateLessonPaymentMutation,
} = paymentsApi;
